import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

const AddEditGateKeeper = ({
  onHide,
  editable,
  setRes,
  setDialog,
  rowData,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [apiRes, setApiRes] = useState();
  const [loading, setLoading] = useState(false);
  const [gateList, setGateList] = useState();
  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^\+92\d{10}$/, "Mobile number must start with +92"),
    name: Yup.string()
      .required("Required")
      .max(30, "Name must be 30 characters"),
    pin: editable
      ? Yup.string().notRequired()
      : Yup.string()
          .min(4, "Pin must be 4 characters if provided")
          .max(30, "Pin must be 30 characters if provided"),
    cnicNumber: Yup.string()
      .required("Required")
      .max(13, "CNIC must be 13 characters"),
    gateId: Yup.string().required("Required"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      phoneNumber: "",
      name: "",
      pin: "",
      newPin: "",
      address: "",
      cnicNumber: "",
      gateId: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        let response;
        if (editable) {
          response = await axios.put(
            `${appUrl.baseUrl}/api/TellerUser/update`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(
            `${appUrl.baseUrl}/api/TellerUser/add`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (response.data && response.data.responseCode === 200) {
          // Success case
          setApiRes(response);
          setRes(response);
          toast.success(
            editable
              ? "Gate Keeper Updated Successfully"
              : "Gate Keeper Added Successfully"
          );
          setDialog(false);
        } else {
          // Error case
          if (response.data && response.data.responseDescription) {
            toast.error(response.data.responseDescription);
          } else {
            toast.error("Failed to perform operation");
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("Failed to perform operation");
      } finally {
        setLoading(false);
      }
    },
  });

  const GetGates = async () => {
    try {
      const response = await fetch(
        `${appUrl.baseUrl}/api/Gates/list?loggedInUserId=${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGateList(data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData?.name);
      formik.setFieldValue("phoneNumber", rowData?.phoneNumber);
      formik.setFieldValue("pin", rowData?.pin);
      formik.setFieldValue("gateId", rowData?.gateId);
      formik.setFieldValue("address", rowData?.address);
      formik.setFieldValue("cnicNumber", rowData?.cnicNumber);
    }
  }, [editable, rowData]);
  useEffect(() => {
    GetGates();
  }, []);

  return (
    <>
      {" "}
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              // onChange={formik.handleChange}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(
                  /[^a-zA-Z\s]/g,
                  ""
                );
                formik.setFieldValue("name", sanitizedValue);
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Mobile Number <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={13}
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              value={formik.values.phoneNumber}
              // onChange={formik.handleChange}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(
                  /(?!^\+)[^\d]/g,
                  ""
                );
                formik.setFieldValue("phoneNumber", sanitizedValue);
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>
          {editable ? null : (
            <div className="field  md:col-6">
              <label htmlFor="" style={{ fontWeight: "bold" }}>
                Pin <span className="Staric-Custom"> *</span>
              </label>
              <InputText
                maxLength={4}
                id="pin"
                name="pin"
                type="text"
                value={formik.values.pin}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("pin", sanitizedValue);
                }}
              />
              {formik.touched.pin && formik.errors.pin ? (
                <div className="error">{formik.errors.pin}</div>
              ) : null}
            </div>
          )}
          {editable ? (
            <div className="field  md:col-6">
              <label htmlFor="" style={{ fontWeight: "bold" }}>
                New Pin <span className="Staric-Custom"> *</span>
              </label>
              <InputText
                maxLength={4}
                id="newPin"
                name="newPin"
                type="text"
                value={formik.values.newPin}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("newPin", sanitizedValue);
                }}
              />
              {formik.touched.newPin && formik.errors.newPin ? (
                <div className="error">{formik.errors.newPin}</div>
              ) : null}
            </div>
          ) : null}

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Pass Number<span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.gateId}
              optionLabel="number"
              name="gateId"
              options={gateList}
              optionValue="id"
              placeholder="Select"
              onChange={formik.handleChange}
            />
            {formik.touched.gateId && formik.errors.gateId ? (
              <div className="error">{formik.errors.gateId}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              CNIC <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              id="cnicNumber"
              name="cnicNumber"
              type="text"
              value={formik.values.cnicNumber}
              // onChange={formik.handleChange}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("cnicNumber", sanitizedValue);
              }}
            />
            {formik.touched.cnicNumber && formik.errors.cnicNumber ? (
              <div className="error">{formik.errors.cnicNumber}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Email{" "}
            </label>
            <InputText
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {/* {formik.touched.email && formik.errors.email ? <div className='error'>{formik.errors.email}</div> : null} */}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Address{" "}
            </label>
            <InputText
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {/* {formik.touched.address && formik.errors.address ? <div className='error'>{formik.errors.address}</div> : null} */}
          </div>

          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-white w100 "
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-btn w100"
                label={editable ? "Update" : "Add "}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditGateKeeper;
