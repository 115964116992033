import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import appUrl from "../../../../constants/appUrl";
import { ConfirmDialog } from "primereact/confirmdialog";

const RegisterScreen = () => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\+92\d{10}$/, "Phone number must start with +92"),
    address: Yup.string().required("Address is required"),
    organizationName: Yup.string().required("Organization Name is required"),
    organizationAddress: Yup.string().required(
      "Organization Address is required"
    ),
    organizationEmail: Yup.string()
      .email("Invalid email format")
      .required("Organization Email is required"),
    organizationPhoneNumber: Yup.string()
      .required("Organization Phone number is required")
      .matches(/^\+92\d{10}$/, "Organization Phone number must start with +92"),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      organizationName: "",
      organizationAddress: "",
      organizationEmail: "",
      organizationPhoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await axios.post(
          `${appUrl.baseUrl}/api/RegistrationRequests/add`,
          values
        );
        // toast.success("Registration successful");
        setShowDialog(true);
      } catch (error) {
        toast.error("Registration failed, please try again");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleRedirect = () => {
    history.push("/");
  };

  const dialogFooter = (
    <div>
      <Button
        label="Okay"
        icon="pi pi-check footer-popup-dialog"
        onClick={handleRedirect}
        style={{ background: "#509793", border: "2px solid #509793" }}
      />
    </div>
  );

  return (
    <div>
      <ToastContainer />

      <ConfirmDialog
        header="Success"
        visible={showDialog}
        footer={dialogFooter}
        message={
          <div style={{ textAlign: "center", lineHeight: "1.5" }}>
            <br />
            Registered Successfully!
            <br />
            It is now awaiting approval from the super admin.
            <br />
          </div>
        }
      />

      <div className="p-fluid formgrid grid auth-login">
        <div className="field md:col-4"></div>
        <div
          className="field md:col-4"
          style={{
            background: "white",
            padding: "40px",
            borderRadius: "10px",
            backdropFilter: "blur(12px)",
          }}
        >
          <div className="text-center">
            <img
              src={"assets/layout/images/Zindigi.svg"}
              alt="logo"
              style={{ width: "100px" }}
            />
          </div>
          <h4 className="auth-welcome mt-3 text-center">
            Register Your Organization Here
          </h4>
          <p className="text-center">Welcome! Please enter your details.</p>

          <form
            onSubmit={formik.handleSubmit}
            className="grid p-fluid justify-content-left align-items-left mt-5"
          >
            <div className="field md:col-12 mb-0">
              <label>
                Name <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                maxLength={30}
                name="name"
                placeholder="Enter Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.name && formik.touched.name ? "p-invalid" : ""
                }
              />
              {formik.errors.name && formik.touched.name && (
                <small className="p-error">{formik.errors.name}</small>
              )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Email <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                name="email"
                placeholder="Enter Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.email && formik.touched.email ? "p-invalid" : ""
                }
              />
              {formik.errors.email && formik.touched.email && (
                <small className="p-error">{formik.errors.email}</small>
              )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Phone Number <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                maxLength={13}
                name="phoneNumber"
                placeholder="Enter Phone Number"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={(e) =>
                  formik.setFieldValue(
                    "phoneNumber",
                    e.target.value.replace(/(?!^\+)[^\d]/g, "")
                  )
                }
                className={
                  formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <small className="p-error">{formik.errors.phoneNumber}</small>
              )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Address <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                maxLength={30}
                name="address"
                placeholder="Enter Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.address && formik.touched.address
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.address && formik.touched.address && (
                <small className="p-error">{formik.errors.address}</small>
              )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Organization Name <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                maxLength={30}
                name="organizationName"
                placeholder="Enter Organization Name"
                value={formik.values.organizationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.organizationName &&
                  formik.touched.organizationName
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.organizationName &&
                formik.touched.organizationName && (
                  <small className="p-error">
                    {formik.errors.organizationName}
                  </small>
                )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Organization Address <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                maxLength={30}
                name="organizationAddress"
                placeholder="Enter Organization Address"
                value={formik.values.organizationAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.organizationAddress &&
                  formik.touched.organizationAddress
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.organizationAddress &&
                formik.touched.organizationAddress && (
                  <small className="p-error">
                    {formik.errors.organizationAddress}
                  </small>
                )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Organization Email <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                name="organizationEmail"
                placeholder="Enter Organization Email"
                value={formik.values.organizationEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.organizationEmail &&
                  formik.touched.organizationEmail
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.organizationEmail &&
                formik.touched.organizationEmail && (
                  <small className="p-error">
                    {formik.errors.organizationEmail}
                  </small>
                )}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Organization Phone Number{" "}
                <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                maxLength={13}
                name="organizationPhoneNumber"
                placeholder="Enter Organization Phone Number"
                value={formik.values.organizationPhoneNumber}
                onChange={formik.handleChange}
                onKeyUp={(e) =>
                  formik.setFieldValue(
                    "organizationPhoneNumber",
                    e.target.value.replace(/(?!^\+)[^\d]/g, "")
                  )
                }
                onBlur={formik.handleBlur}
                className={
                  formik.errors.organizationPhoneNumber &&
                  formik.touched.organizationPhoneNumber
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.organizationPhoneNumber &&
                formik.touched.organizationPhoneNumber && (
                  <small className="p-error">
                    {formik.errors.organizationPhoneNumber}
                  </small>
                )}
            </div>

            <div className="field md:col-12 mb-0">
              <Button
                type="submit"
                className="custom-btn mt-3"
                label="Register"
                disabled={formik.isSubmitting}
              />
            </div>
          </form>

          <p className="mx-auto" style={{ textAlign: "center" }}>
            Already have an account? <a onClick={handleRedirect}>Login here</a>
          </p>
        </div>
        <div className="field md:col-4"></div>
      </div>
    </div>
  );
};

export default RegisterScreen;
