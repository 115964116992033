import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import appUrl from "../../../../constants/appUrl";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/loader";
import { useDispatch, useSelector } from "react-redux";

// Define the function before the component
const getLast30DaysDates = () => {
  const now = new Date();
  const end = new Date(now);
  const start = new Date(now.setDate(now.getDate() - 30));

  return {
    start: start.toISOString().split("T")[0],
    end: end.toISOString().split("T")[0],
  };
};

const Attendus = () => {
  const [gateList, setGateList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [attendusList, setAttendusList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { start: defaultStartDate, end: defaultEndDate } = getLast30DaysDates();

  const validationSchema = Yup.object({
    // Define validation schema if needed
  });

  const { user } = useSelector((state) => state.auth);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      searchText: "",
      gateId: null,
      groupId: null,
      form: defaultStartDate,
      to: defaultEndDate,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${appUrl.baseUrl}/api/Attendances`,
          values,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setAttendusList(response.data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const GetGates = async () => {
    try {
      const response = await fetch(
        `${appUrl.baseUrl}/api/Gates/list?loggedInUserId=${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGateList(data?.data);
    } catch (error) {
      console.error("Error fetching gates:", error);
    }
  };

  const GetGroups = async () => {
    try {
      const response = await fetch(
        `${appUrl.baseUrl}/api/Groups/list?loggedInUserId=${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGroupList(data?.data);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    formik.handleSubmit();
    GetGates();
    GetGroups();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card pb-3">
          <div className="p-fluid formgrid grid">
            <div className="field md:col-2">
              <label htmlFor="searchText" style={{ fontWeight: "bold" }}>
                Search
              </label>
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  id="searchText"
                  name="searchText"
                  placeholder="Search"
                  type="text"
                  value={formik.values.searchText}
                  onChange={formik.handleChange}
                />
              </span>
              {formik.touched.searchText && formik.errors.searchText ? (
                <div className="error">{formik.errors.searchText}</div>
              ) : null}
            </div>
            <div className="field md:col-2">
              <label className="mb-2" style={{ fontWeight: "bold" }}>
                Gate
              </label>
              <Dropdown
                value={formik.values.gateId}
                optionLabel="number"
                name="gateId"
                options={gateList}
                optionValue="id"
                placeholder="Select"
                filter
                onChange={formik.handleChange}
              />
              {formik.touched.gateId && formik.errors.gateId ? (
                <div className="error">{formik.errors.gateId}</div>
              ) : null}
            </div>
            <div className="field md:col-2">
              <label htmlFor="form" style={{ fontWeight: "bold" }}>
                From Date
              </label>
              <InputText
                id="form"
                name="form"
                type="date"
                value={formik.values.form}
                onChange={formik.handleChange}
              />
              {formik.touched.form && formik.errors.form ? (
                <div className="error">{formik.errors.form}</div>
              ) : null}
            </div>
            <div className="field md:col-2">
              <label htmlFor="to" style={{ fontWeight: "bold" }}>
                To Date
              </label>
              <InputText
                id="to"
                name="to"
                type="date"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
              {formik.touched.to && formik.errors.to ? (
                <div className="error">{formik.errors.to}</div>
              ) : null}
            </div>
            <div className="md:col-10"></div>
            <div className="md:col-2">
              <Button className="custom-btn w80" label="Search" type="submit" />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="grid">
            <div className="md:col-12">
              {loading && <Loader />}
              <DataTable
                value={attendusList}
                scrollable
                scrollHeight="300px"
                paginator
                rows={10}
                globalFilterFields={["name", "cnic", "mobile"]}
              >
                <Column field="personName" header="Person Name" />
                <Column field="personCnic" header="CNIC" />
                <Column field="personMobileNumber" header="Mobile Number" />
                <Column
                  field="checkedIn"
                  header="Checked In"
                  body={(rowData) =>
                    new Date(rowData.checkedIn).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }
                />
                <Column
                  field="checkedOut"
                  header="Checked Out"
                  body={(rowData) =>
                    new Date(rowData.checkedOut).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }
                />
                <Column field="gateNumber" header="Gate Number" />
                <Column field="gateKeeperName" header="Gate Keeper Name" />
              </DataTable>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Attendus;
