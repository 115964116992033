import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditRequest from "../components/add_edit_registration-requests";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import EditRequestStatus from "../components/edit_registration-requests";
import { Tag } from "primereact/tag";
import { TabMenu } from "primereact/tabmenu";

const RegistrationRequests = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [editDialog, setEditDialog] = useState();
  const [gateList, setGateList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editableTwo, setEditableTwo] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // New state for active tab
  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const GetPendingRequests = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${appUrl.baseUrl}/api/RegistrationRequests/all?status=pending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGateList(data?.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const GetRejectedRequests = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${appUrl.baseUrl}/api/RegistrationRequests/all?status=rejected`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setRejectedList(data?.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const GetApprovedRequests = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${appUrl.baseUrl}/api/RegistrationRequests/all?status=approved`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setApprovedList(data?.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetPendingRequests();
    GetApprovedRequests();
    GetRejectedRequests();
  }, [res]);

  const onHide = () => {
    setDialog(false);
  };

  const onHideTwo = () => {
    setEditDialog(false);
  };

  const confirm = (rowData) => {
    confirmDialog({
      header: (
        <div className="custom-header mb-3 mt-3">
          {/* <i className="pi pi-trash custom-btn-delete"></i> */}
        </div>
      ),
      message: (
        <strong>Are you sure you want to delete this Organization?</strong>
      ),
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };

  const editActionTwo = (rowData) => {
    setEditableTwo(true);
    setRowselect(rowData);
    setEditDialog(true);
  };

  const accept = async (rowData) => {
    try {
      const response = await axios.delete(
        `${appUrl.baseUrl}/api/Organizations/delete/${rowData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {}
  };

  const reject = (rowData) => {
    return;
  };

  const actionTemplate = (rowData) => (
    <div>
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editActionTwo(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const formattedStatus = (rowData) => {
    return (
      <div>
        <Tag
          style={{
            width: "100px",
            textTransform:"uppercase",
            whiteSpace: "nowrap",
            backgroundColor:
              rowData.status === "Approved"
                ? "#22C55E"
                : rowData.status === "Rejected"
                ? "#EF4444"
                : "#6366F1",
          }}
        >
          {rowData.status === "Pending"
            ? "Pending"
            : rowData.status === "Approved"
            ? "Approved"
            : "Rejected"}
        </Tag>
      </div>
    );
  };

  // Tab items
  const items = [
    { label: "Pending" },
    { label: "Approved" },
    { label: "Rejected" },
  ];

  // Handle tab change
  const handleTabChange = (e) => {
    setActiveTab(e.index); // Update active tab index
  };

  return (
    <>
      <ToastContainer />
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={"Add Request"}
        style={{ width: "50vw" }}
      >
        <AddEditRequest
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>
      <Dialog
        visible={editDialog}
        onHide={onHideTwo}
        header={"Update Registration Request Status"}
        style={{ width: "50vw" }}
      >
        <EditRequestStatus
          dialog={editDialog}
          editable={editableTwo}
          rowData={rowselect}
          setDialog={setEditDialog}
          onHide={onHideTwo}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />
        </div>
      </div>

      <div className="card">
        <TabMenu
          model={items}
          activeIndex={activeTab}
          onTabChange={handleTabChange}
        />
        <DataTable
          ref={dt}
          value={
            activeTab === 0
              ? gateList
              : activeTab === 1
              ? approvedList
              : rejectedList
          }
          paginator
          rows={10}
          responsiveLayout="scroll"
          emptyMessage="No records found"
          filters={filters}
          globalFilterFields={["name", "status"]}
          stripedRows
        >
          <Column field="id" header="ID" />
          <Column field="name" header="Name" />
          <Column field="organizationName" header="Organization Name" />
          <Column field="organizationEmail" header="Organization Email" />
          <Column field="organizationPhoneNumber" header="Organization Phone" />
          <Column field="status" header="Status" body={formattedStatus} />
          {activeTab === 0 && (
            <Column
              field=""
              header="Action"
              body={actionTemplate}
              style={{ minWidth: "12rem" }}
            />
          )}
        </DataTable>
      </div>
    </>
  );
};

export default RegistrationRequests;
