import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddEditGates = ({ onHide, editable, setRes, setDialog, rowData }) => {

  const [apiRes, setApiRes] = useState();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    number: Yup.string()
      .required("Required")
      .max(5, "Mobile number must be 5 characters")
      .test(
        "not-zero",
        "Gate number must not be zero",
        (value) => value !== "0"
      ),
    description: Yup.string()
      .required("Required")
      .max(30, "Description must be 30 characters"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      number: "",
      description: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        let response;
        if (editable) {
          data.id = rowData.id;
          const updateData = {
            id: rowData.id,
            number: data.number,
            description: data.description,
          };
          response = await axios.put(
            `${appUrl.baseUrl}/api/Gates/update`,
            updateData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(`${appUrl.baseUrl}/api/Gates/add`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          });
        }

        if (response.data && response.data.responseCode === 200) {
          // Success case
          setApiRes(response);
          setRes(response);
          toast.success(
            editable ? "Pass Updated Successfully" : "Pass Added Successfully"
          );
          setDialog(false);
        } else {
          // Error case
          if (response.data && response.data.responseDescription) {
            toast.error(response.data.responseDescription);
          } else {
            toast.error("Failed to perform operation");
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("Failed to perform operation");
      } finally {
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    if (editable) {
      formik.setFieldValue("description", rowData?.description);
      formik.setFieldValue("number", rowData?.number);
    }
  }, [editable, rowData]);

  return (
    <>
      {" "}
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Pass No <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={3}
              id="number"
              name="number"
              type="text"
              value={formik.values.number}
              // onChange={formik.handleChange}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("number", sanitizedValue);
              }}
            />
            {formik.touched.number && formik.errors.number ? (
              <div className="error">{formik.errors.number}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Description <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              maxLength={50}
              id="description"
              name="description"
              type="text"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="error">{formik.errors.description}</div>
            ) : null}
          </div>
          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-white w100 "
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-btn w100"
                label={editable ? "Update" : "Add "}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditGates;
