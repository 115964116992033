import { Button } from 'primereact/button'
import React from 'react'
import { useFormik } from 'formik'
import { InputText } from "primereact/inputtext";
import * as Yup from 'yup';
const AddEditUser = ({onHide,editable}) => {
  const validationSchema = Yup.object({
    number: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
    name: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
    password: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
    role: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
    gate: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
   
});
const formik = useFormik({
  validationSchema: validationSchema,
  initialValues: {
      number: '',
      name:'',
      password:'',
      role:'',
      gate:'',
    
  },
  
  onSubmit: async (data) => {
   
},

});

  return (
    <form 
    onSubmit={formik.handleSubmit}
    >
      <div className="p-fluid formgrid grid">
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>Number  <span className='Staric-Custom'> *</span></label>
          <InputText
            id="number"
            name="number"
            type="text"
            value={formik.values.number}
            onChange={formik.handleChange}
          />
          {formik.touched.number && formik.errors.number ? <div className='error'>{formik.errors.number}</div> : null}
        </div>
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>Name  <span className='Staric-Custom'> *</span></label>
          <InputText
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
        </div>
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>Password  <span className='Staric-Custom'> *</span></label>
          <InputText
            id="password"
            name="password"
            type="text"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {formik.touched.password && formik.errors.password ? <div className='error'>{formik.errors.password}</div> : null}
        </div>
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>Role  <span className='Staric-Custom'> *</span></label>
          <InputText
            id="role"
            name="role"
            type="text"
            value={formik.values.role}
            onChange={formik.handleChange}
          />
          {formik.touched.role && formik.errors.role ? <div className='error'>{formik.errors.role}</div> : null}
        </div>
        <div className="field  md:col-6">
          <label htmlFor="" style={{ fontWeight: "bold" }}>Gate  <span className='Staric-Custom'> *</span></label>
          <InputText
            id="gate"
            name="gate"
            type="text"
            value={formik.values.gate}
            onChange={formik.handleChange}
          />
          {formik.touched.gate && formik.errors.gate ? <div className='error'>{formik.errors.gate}</div> : null}
        </div>

        <div className="col-12 md:col-8"></div>
        <div className="col-12 md:col-2" >
          <div className="flex justify-content-center">
            <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
          </div>
        </div>
        <div className="col-12 md:col-2" >
          <div className="flex justify-content-center">
            <Button className="custom-btn w100" label={editable ? 'Update' : 'Add '} type='submit'  />
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddEditUser