import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditOrganization from "../components/add_edit_organization";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import deleteIcon from "../../../../../assets/icons/trash.png";
import { formatDate, emailsRenderTextWithTooltip, renderTextWithTooltip } from "../../../components/helper";

const Organization = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [gateList, setGateList] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const GetGates = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${appUrl.baseUrl}/api/Organizations/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setGateList(data?.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const onHide = () => {
    setDialog(false);
  };
  const confirm = (rowData) => {
    confirmDialog({
      header: (
        <div className="custom-header mb-3 mt-3">
          {/* <i className="pi pi-trash custom-btn-delete"></i> */}
        </div>
      ),
      message: (
        <strong>Are you sure you want to delete this Organization?</strong>
      ),
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };
  const accept = async (rowData) => {
    try {
      const response = await axios.delete(
        `${appUrl.baseUrl}/api/Organizations/delete/${rowData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {}
  };
  const reject = (rowData) => {
    return;
  };
  const actionTemplate = (rowData) => (
    <div>
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>

      <Button
        className="custom-btn-delete"
        onClick={() => {
          confirm(rowData);
        }}
      >
        <img
          src={deleteIcon}
          alt="Delete"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  useEffect(() => {
    GetGates();
  }, [res]);
  return (
    <>
      <ToastContainer />
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Organization" : "Add Organization"}
        // header="Add Organizations"
        style={{ width: "50vw" }}
      >
        <AddEditOrganization
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn  ml-3"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={gateList}
          scrollable
          // scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={["number"]}
        >
          <Column field="name" header="Organization Name" />
          <Column field="phoneNumber" header="Phone Number" />
          {/* <Column field="address" header="Address" /> */}
          <Column 
  field="address" 
  header="Address" 
  body={(rowData) => renderTextWithTooltip(rowData, "address", "bottom")} 
/>

          <Column
  field="email"
  header="Email"
  body={(rowData) => emailsRenderTextWithTooltip(rowData, "email", "bottom")}
/>

          <Column
            header="Profile Image"
            body={(rowData) => (
              <img
                src={`${appUrl.baseUrl}${rowData.logoUrl}`}
                alt="Profile"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
            )}
          />
        <Column
            field="createdDate"
            header="Date"
            body= {(rowData) => formatDate(rowData.createdDate)}
          />
          {/* <Column
            field="modifiedDate"
            header="Updated Date"
            body={(rowData) => {
              if (!rowData.modifiedDate) {
                return "-";
              }
              return new Date(rowData.modifiedDate).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
            }}
          /> */}
          <Column
            field=""
            header="Action"
            body={actionTemplate}
            style={{ minWidth: "12rem" }}
          />
        </DataTable>
      </div>
    </>
  );
};

export default Organization;
