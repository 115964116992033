import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEditGateKeeper from "../components/add_edit_gatekeeper";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import { formatDate, renderTextWithTooltip } from "../../../components/helper";
import { ToggleButton } from "primereact/togglebutton";
import { Tag } from "primereact/tag";
import editIcon from "../../../../../assets/icons/bx_edit.png";

const GateKeeper = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [gateKeeperList, setGateKeeperList] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const dt = useRef(null);
  const [activeStatusMap, setActiveStatusMap] = useState({});

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const GetGatesKeeper = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${appUrl.baseUrl}/api/TellerUser/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setGateKeeperList(data?.data);
      setLoading(false);
    } catch (error) {}
  };

  const onHide = () => {
    setDialog(false);
  };
  const confirm = (rowData) => {
    confirmDialog({
      header: (
        <div className="custom-header mb-3 mt-3">
          {/* <i className="pi pi-trash custom-btn-delete"></i> */}
        </div>
      ),
      message: (
        <strong>Are you sure you want to delete this Gate Keeper?</strong>
      ),
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };
  const accept = async (rowData) => {
    try {
      const response = await axios.delete(
        `${appUrl.baseUrl}/api/GateKeepers/${rowData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {}
  };
  const reject = (rowData) => {
    return;
  };

  const toggleStatus = async (rowData) => {
    const newStatus = !activeStatusMap[rowData.id];
    setActiveStatusMap((prev) => ({
      ...prev,
      [rowData.id]: newStatus,
    }));

    try {
      await axios.put(
        `${appUrl.baseUrl}/api/TellerUser/update/status`,
        {
          id: rowData.id,
          isActive: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(`Status updated to ${newStatus ? "Active" : "In-Active"}`);
    } catch (error) {
      setActiveStatusMap((prev) => ({
        ...prev,
        [rowData.id]: !newStatus,
      }));
      toast.error("Failed to update status");
    }
  };

  const actionTemplate = (rowData) => (
    <div>
      <Button
        className="custom-btn-edit"
        onClick={() => editAction(rowData)}
        >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
      {/* <ToggleButton
        checked={activeStatusMap[rowData.id] || false}
        onChange={() => toggleStatus(rowData)}
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        onLabel="Active"
        offLabel="Inactive"
        className="ml-2"
      /> */}
    </div>
  );

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  useEffect(() => {
    GetGatesKeeper();
  }, [res]);

  const columnsData = [
    // {
    //   header: "Pass Number",
    //   field: "gateNumber",
    // },
    {
      header: "Name",
      field: "name",
      body: (rowData) => renderTextWithTooltip(rowData, "name", "bottom"),
    },
    {
      header: "Mobile Number",
      field: "phoneNumber",
    },
    {
      header: "CNIC",
      field: "cnicNumber",
    },
    {
      header: "Email",
      field: "email",
      body: (rowData) => renderTextWithTooltip(rowData, "email", "bottom"),
    },
    {
      header: "Address",
      field: "address",
      body: (rowData) => renderTextWithTooltip(rowData, "address", "bottom"),
    },
    {
      header: "Status",
      body: (rowData) => (
        <Tag
          className={rowData.isActive ? "p-tag-success" : "p-tag-danger"}
          style={{ width: "100%", whiteSpace: "nowrap" }}
        >
          {rowData.isActive ? "Active" : "Non-active"}
        </Tag>
      ),
    },
    {
      header: "Date",
      field: "modifiedDate",
      body: (rowData) => formatDate(rowData.modifiedDate),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Gate Keeper" : "Add Gate Keeper"}
        //header="Add Gate Keeper"
        style={{ width: "50vw" }}
      >
        <AddEditGateKeeper
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn  ml-3"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>
      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={gateKeeperList}
          globalFilterFields={["name", "userName", "mobileNumber"]}
          scrollable
          scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default GateKeeper;
