import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appUrl from '../../../../constants/appUrl';

const UpdateRequestStatus = ({ onHide, rowData, setRes, setDialog }) => {
  const [loading, setLoading] = useState(false);

  const statusOptions = [
    { label: 'Approve', value: true },
    { label: 'Reject', value: false }
  ];

  const validationSchema = Yup.object({
    isApproved: Yup.string().required('Status is required'),
    remarks: Yup.string().when('status', {
      is: false,
      then: Yup.string().required('Remarks are required when rejecting the request'),
      otherwise: Yup.string()
    }),
  });

  const formik = useFormik({
    // validationSchema: validationSchema,
    initialValues: {
      isApproved: '',
      remarks: ''
    },
    onSubmit: async (data) => {
      setLoading(true);
      try {
        const payload = {
          id: rowData.id,
          isApproved: data.status,
          remarks: data.remarks
        };

        const response = await axios.put(`${appUrl.baseUrl}/api/RegistrationRequests/update/status`, payload, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.data && response.data.responseCode === 200) {
          setRes(response);
          toast.success("Request status updated successfully");
          setDialog(false);
        } else {
          toast.error(response.data?.responseDescription || "Failed to update status");
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("Failed to update status");
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <>  
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-12">
            <label htmlFor="status" style={{ fontWeight: "bold" }}>Status <span className='Staric-Custom'> *</span></label>
            <Dropdown
              id="status"
              name="status"
              value={formik.values.status}
              options={statusOptions}
              onChange={formik.handleChange}
              placeholder="Select Status"
            />
            {formik.touched.status && formik.errors.status ? <div className='error'>{formik.errors.status}</div> : null}
          </div>

          <div className="field md:col-12">
            <label htmlFor="remarks" style={{ fontWeight: "bold" }}>Remarks {formik.values.status === false && <span className='Staric-Custom'> *</span>}</label>
            <InputText
              id="remarks"
              name="remarks"
              type="text"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              disabled={formik.values.status !== false}
            />
            {formik.touched.remarks && formik.errors.remarks ? <div className='error'>{formik.errors.remarks}</div> : null}
          </div>
{/* 
          <div className="field md:col-12">
            <Button label={loading ? 'Submitting...' : 'Update Status'} type="submit" className="p-button-success" disabled={loading} />
          </div> */}

          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-white w100 "
                label="Cancel"
                type="button"
                disabled={loading}
                onClick={onHide}
              />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-btn w100"
                label="Update"
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateRequestStatus;
