import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appUrl from "../../constants/appUrl";

// Thunk for handling password update
export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (credentials, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");  // Retrieve the token from local storage

      const response = await axios.put(
        `${appUrl.baseUrl}/api/Admin/update/password`, 
        credentials, 
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Correct header for bearer token
            "Content-Type": "application/json"
          }
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data?.responseDescription || 'Update failed');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network error');
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    passwordUpdated: false, // Track if password update was successful
  },
  reducers: {
    clearUpdateState(state) {
      state.passwordUpdated = false; // Reset update state
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle password update
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.passwordUpdated = true; // Set flag to true on success
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Capture error message
      });
  },
});

export const { clearUpdateState } = authSlice.actions;
export default authSlice.reducer;
