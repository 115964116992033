import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import logo from "./assets/logo/logo.png";

export const AppTopbar = (props) => {
  // const location = useLocation();
  const history = useHistory();
  const confirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
      acceptClassName: "custom-bg color-white",
      rejectClassName: "custom-white",
    });
  };

  const accept = () => {
    window.localStorage.clear();
    localStorage.removeItem("login");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    history.push("./");
  };
  const reject = () => {
    return;
  };

  return (
    <>
      <ConfirmDialog />

      <div className="layout-topbar">
        <Link to="/" className="layout-topbar-logo">
          <img src={logo} alt="logo" />
          <span className="custom-color">Virtual Card</span>
        </Link>
        {props.currentRouteLabel && (
          <div className="layout-topbar-heading">{props.currentRouteLabel}</div>
        )}

        <button
          type="button"
          className="p-link  layout-menu-button layout-topbar-button mobile-show"
          onClick={props.onToggleMenuClick}
        >
          <i className="pi pi-bars" />
        </button>

        <button
          type="button"
          className="p-link layout-topbar-menu-button layout-topbar-button"
          onClick={props.onMobileTopbarMenuClick}
        >
          <i className="pi pi-ellipsis-v" />
        </button>

        <ul
          className={classNames("layout-topbar-menu lg:flex origin-top", {
            "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
          })}
        >
          <li>
            {/* <button className="p-link layout-topbar-button" onClick={props.onSettingsClick}>
                <i className="pi pi-cog" />
                <span>Settings</span>
            </button> */}
          </li>
          <li>
            <button className="p-link layout-topbar-button" onClick={confirm}>
              <i className="pi pi-power-off" />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};
