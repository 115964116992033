import React  from "react";
import { Tooltip } from 'primereact/tooltip';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date
    .toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/,/, "");
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatCapitalizeFirstLetter = (rowData, field) => {
  if (!rowData || rowData[field] === undefined || rowData[field] === null) {
    return "";
  }

  return rowData[field]
    .split(' ')
    .map(word => capitalizeFirstLetter(word))
    .join(' ');
};

export const renderTextWithTooltip = (rowData, field, tooltipPosition = "bottom") => {

  const capitalizedDescription = formatCapitalizeFirstLetter(rowData, field);

  if (!capitalizedDescription) {
    return "N/A";
  }

  const shortDescription = capitalizedDescription
    .split(" ")
    .slice(0, 2)
    .join(" ");   
  
  return (
    <div
      className="hoverable-description"
      data-pr-tooltip={capitalizedDescription}
      data-pr-position={tooltipPosition}
      data-pr-tooltip-options={{
        position: tooltipPosition,
        mouseTrack: true,
        mouseTrackTop: 15,
      }}
    >
      {shortDescription}
      {capitalizedDescription.split(" ").length > 2 && <span>...</span>}
      <Tooltip target=".hoverable-description" />
    </div>
  );
};

export const emailsRenderTextWithTooltip = (rowData, field, tooltipPosition = "bottom") => {
  const email = rowData[field]; // Get the email value

  if (!email) {
    return "N/A"; // Return "N/A" if no email is found
  }

  // Shorten the email by showing the first part with "..."
  const [username, domain] = email.split("@"); // Split email into username and domain
  const shortUsername = username.length > 5 ? `${username.slice(0, 5)}...` : username; // Show first 5 characters and then "..." if the username is long
  const shortDescription = `${shortUsername}@${domain}`;

  return (
    <div
      className="hoverable-description"
      data-pr-tooltip={email} // Show full email in the tooltip
      data-pr-position={tooltipPosition}
      data-pr-tooltip-options={{
        position: tooltipPosition,
        mouseTrack: true,
        mouseTrackTop: 15,
      }}
    >
      {shortDescription} {/* Display shortened version */}
      <Tooltip target=".hoverable-description" />
    </div>
  );
};
