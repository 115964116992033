import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import { Switch } from "react-router-dom";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Attendus from "./app/features/screens/attendus/pages/attendus";
import Gates from "./app/features/screens/gates/pages/gates";
import Groups from "./app/features/screens/groups/pages/groups";
import People from "./app/features/screens/people/pages/people";
import Users from "./app/features/screens/users/pages/users";
import LoginScreen from "./app/features/screens/auth/pages/login_screen";
import GateKeeper from "./app/features/screens/gatekeeper/pages/gate_keeper";
import Organization from "./app/features/screens/organizations/pages/organization";
import Branches from "./app/features/screens/branches/pages/branches";
import { useSelector } from "react-redux";
import RegistrationRequests from "./app/features/screens/registrationRequests/pages/registrationRequests";
import AdminControl from "./app/features/screens/adminControl/pages/adminControl";
import RegisterScreen from "./app/features/screens/auth/pages/register_screen";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user); // Get user info from Redux store
  const userRole = user?.role || "";
  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      items:
        userRole === "SuperAdministrator"
          ? [
              {
                label: "User management",
                icon: require("./assets/icons/Admin.svg"),
                to: "/superAdmin-admin",
              },
              {
                label: "Organizations",
                icon: require("./assets/icons/Gates.svg"),
                to: "/organizations",
              },
              // { label: 'Branches', icon: require("./assets/icons/Groups.svg"), to: '/branches' },
              {
                label: "Registration Requests",
                icon: require("./assets/icons/People.svg"),
                to: "/registration-requests",
              },
            ]
          : [
              {
                label: "Pass",
                icon: require("./assets/icons/Gates.svg"),
                to: "/",
              },
              {
                label: "Groups",
                icon: require("./assets/icons/Groups.svg"),
                to: "/groups",
              },
              {
                label: "People",
                icon: require("./assets/icons/People.svg"),
                to: "/peoples",
              },
              {
                label: "Gate Keeper",
                icon: require("./assets/icons/Gate Keeper.svg"),
                to: "/gatekeeper",
              },
              {
                label: "Attendance",
                icon: require("./assets/icons/Attendance.svg"),
                to: "/attendus",
              },
            ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const findCurrentRouteLabel = (location) => {
    const path = location.pathname;
    let currentRouteLabel = "";

    for (const group of menu) {
      for (const subGroup of group.items || []) {
        if (!subGroup.items) {
          // If there are no sub-items, check if the item itself matches the path
          if (subGroup.to === path) {
            return subGroup.label || "";
          }
        } else {
          for (const item of subGroup.items || []) {
            if (item.to === path) {
              const labels = [group.label, subGroup.label, item.label].filter(
                (label) => label
              ); // Filter out empty labels
              currentRouteLabel = labels.join(" - "); // Join labels with dash
              return currentRouteLabel;
            }
          }
        }
      }
    }
    return currentRouteLabel;
  };
  return (
    <>


      {localStorage.getItem("login") === null ||
      localStorage.getItem("login") === undefined ? (
        <div
          className="layout-main-container auth_main"
          style={{
            backgroundImage: 'url("assets/layout/images/Group.png")',
            padding: "9rem 1rem 0rem 0rem",
          }}
        >
          <div className="layout-main">
          <Route exact path="/register" component={RegisterScreen} />
            <Route exact path="/" component={LoginScreen} />
          </div>
        </div>
      ) : 
      (
        
        <div className={wrapperClass} onClick={onWrapperClick}>
          <Tooltip
            ref={copyTooltipRef}
            target=".block-action-copy"
            position="bottom"
            content="Copied to clipboard"
            event="focus"
          />
          

          <AppTopbar
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            currentRouteLabel={findCurrentRouteLabel(location)}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div className="layout-sidebar" onClick={onSidebarClick}>
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container">
            <div className="layout-main">
              <Switch>
            
                {/* For super admin */}
                <Route
                  path="/superAdmin-admin"
                  exact
                  component={AdminControl}
                />
                <Route path="/organizations" exact component={Organization} />
                {/* <Route path="/branches" exact component={Branches} /> */}
                <Route
                  path="/registration-requests"
                  exact
                  component={RegistrationRequests}
                />

                {/* for admin */}
                <Route path="/" exact component={Gates} />
                <Route path="/attendus" exact component={Attendus} />
                <Route path="/groups" exact component={Groups} />
                <Route path="/peoples" exact component={People} />
                <Route path="/users" exact component={Users} />
                <Route path="/gatekeeper" exact component={GateKeeper} />
              </Switch>
            </div>

            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
          </div>

          <AppConfig
            rippleEffect={ripple}
            onRippleEffect={onRipple}
            inputStyle={inputStyle}
            onInputStyleChange={onInputStyleChange}
            layoutMode={layoutMode}
            onLayoutModeChange={onLayoutModeChange}
            layoutColorMode={layoutColorMode}
            onColorModeChange={onColorModeChange}
          />

          <CSSTransition
            classNames="layout-mask"
            timeout={{ enter: 200, exit: 200 }}
            in={mobileMenuActive}
            unmountOnExit
          >
            <div className="layout-mask p-component-overlay"></div>
          </CSSTransition>
        </div>
      )}
    </>
  );
};

export default App;
