import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditGates from "../components/add_edit_gates";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import { useSelector } from "react-redux";
import { formatDate, renderTextWithTooltip } from "../../../components/helper";
import CustomImagePreview from "../../../components/custom_imagepreview";
import editIcon from "../../../../../assets/icons/bx_edit.png";

const Gates = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [gateList, setGateList] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [expandedNestedRows, setExpandedNestedRows] = useState(null);

  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const { user } = useSelector((state) => state.auth);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const GetGates = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${appUrl.baseUrl}/api/Gates/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setGateList(data?.data);
      setLoading(false);
    } catch (error) {}
  };

  const onHide = () => {
    setDialog(false);
  };
  const confirm = (rowData) => {
    confirmDialog({
      header: (
        <div className="custom-header">Delete Pass</div>
      ),
      icon: "pi pi-info-circle",
      message: <strong>Are you sure you want to delete this Pass?</strong>,
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };
  const accept = async (rowData) => {
    try {
      const response = await axios.delete(
        `${appUrl.baseUrl}/api/Gates/delete/${rowData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {}
  };
  const reject = (rowData) => {
    return;
  };
  const actionTemplate = (rowData) => (
    <div>
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
        >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
      <Button
        icon="pi pi-trash"
        className="custom-btn-delete"
        onClick={() => {
          confirm(rowData);
        }}
      />
    </div>
  );
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  useEffect(() => {
    GetGates();
  }, [res]);

  const imageNestedTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.logoUrl} />
      </div>
    );
  };

  const columnsData = [
    {
      header: "Pass No",
      field: "number",
    },
    {
      field: "createdDate",
      header: "Created Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      field: "description",
      header: "Description",
      body: (rowData) =>
        renderTextWithTooltip(rowData, "description", "bottom"),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const nestedNestedColumnsData = [
    {
      field: "name",
      header: "Organization Name",
    },
    {
      field: "address",
      header: "Address",
      body: (rowData) => renderTextWithTooltip(rowData, "address", "bottom"),
    },
    {
      field: "email",
      header: "Email",
    },
    {
      field: "phoneNumber",
      header: "Phone Number",
      body: (rowData) => (rowData.phoneNumber ? rowData.phoneNumber : "N/A"),
    },
    {
      header: "Image",
      body: imageNestedTemplate,
    },
  ];

  const nestedRowExpansionTemplate = (data) => {
    const organizationData = data.organization;
    return (
      <div className="p-3">
        <DataTable value={[organizationData]}>
          {nestedNestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <ToastContainer />
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Gate" : "Add Gate"}
        style={{ width: "50vw" }}
      >
        <AddEditGates
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn  ml-3"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={gateList}
          scrollable
          scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={["number"]}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedNestedRows}
          onRowToggle={(e) => setExpandedNestedRows(e.data)}
          rowExpansionTemplate={nestedRowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default Gates;
