import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../../redux/auth_slice/login_user_slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import {
  clearUpdateState,
  updatePassword,
} from "../../../../redux/update_password_slice/update_password_slice";

const LoginScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { accessToken, user, error, loading } = useSelector(
    (state) => state.auth
  );

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState(null);
  const [mustChangePassword, setMustChangePassword] = useState(false);

  const validationSchema = Yup.object({
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^\+92\d{10}$/, "Mobile number must start with +92"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      mobileNumber: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        const resultAction = await dispatch(loginUser(values));

        if (loginUser.fulfilled.match(resultAction)) {
          const mustChangePasswordStatus =
            resultAction.payload.user?.mustChangePassword || false;
          const userRole = resultAction.payload.user?.role || "";
          setPassword(values.password);
          setUserId(resultAction.payload.user.id);
          setMustChangePassword(mustChangePasswordStatus);

          // If must change password is true, stop navigation and show the dialog
          if (mustChangePasswordStatus) {
            setShowPasswordDialog(true);
            // return; // Stop further execution to prevent navigation
          } else {
            // Navigate if mustChangePasswordStatus is false
            if (
              userRole === "SuperAdministrator" ||
              userRole === "Administrator"
            ) {
              toast.success("User Login Successfully");
              if (userRole === "SuperAdministrator") {
                history.push("superAdmin-admin");
              } else {
                history.push("/");
              }
            } else {
              toast.error("You do not have the required role.");
            }
          }
        } else {
          toast.error(resultAction.payload);
        }
      } catch (error) {
        toast.error("Login failed due to a network error. Please try again.");
      }
    },
  });

  const changePasswordValidationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formikPasswordChange = useFormik({
    validationSchema: changePasswordValidationSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      try {
        // Include the userId along with the password
        const payload = {
          id: userId, // Ensure userId is set from the login response
          password: values.password,
        };

        const resultAction = await dispatch(updatePassword(payload));

        if (updatePassword.fulfilled.match(resultAction)) {
          toast.success("Password updated successfully");
          setShowPasswordDialog(false);
          dispatch(clearUpdateState());
        } else {
          toast.error(resultAction.payload || "Update failed");
        }
      } catch (error) {
        toast.error(
          "Password update failed due to a network error. Please try again."
        );
      }
    },
  });

  const handleRedirect = () => {
    history.push("/register");
  };

  return (
    <div>
      <ToastContainer />
      {showPasswordDialog && (
        <Dialog
          visible={showPasswordDialog}
          header="Change Your Password"
          onHide={() => setShowPasswordDialog(false)}
          footer={
            <Button
              type="submit"
              label="Change Password"
              onClick={formikPasswordChange.handleSubmit}
              className="custom-btn mt-3"
            />
          }
        >
          <form
            className="p-fluid formgrid grid auth-login"
            onSubmit={formikPasswordChange.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label>Password</label>
              <Password
                id="password"
                name="password"
                placeholder="Enter password"
                value={formikPasswordChange.values.password}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.password &&
              formikPasswordChange.errors.password ? (
                <div className="error">
                  {formikPasswordChange.errors.password}
                </div>
              ) : null}
            </div>
            <div className="field md:col-12 mb-0">
              <label>Confirm Password</label>
              <Password
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formikPasswordChange.values.confirmPassword}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.confirmPassword &&
              formikPasswordChange.errors.confirmPassword ? (
                <div className="error">
                  {formikPasswordChange.errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </form>
        </Dialog>
      )}

      <div className="p-fluid formgrid grid auth-login">
        <div className="field md:col-4"></div>
        <div
          className="field md:col-4"
          style={{
            background: "white",
            padding: "40px",
            borderRadius: "10px",
            backdropFilter: "blur(12px)",
          }}
        >
          <div className="text-center">
            <img
              src={"assets/layout/images/Zindigi.svg"}
              alt="logo"
              style={{ width: "100px" }}
            />
          </div>
          <h4 className="auth-welcome mt-3 text-center">
            Log in to your account
          </h4>
          <p className="text-center">
            Welcome back! Please enter your details.
          </p>
          <form
            style={{ width: "100%" }}
            className="grid p-fluid justify-content-left align-items-left mt-5"
            onSubmit={formik.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label className="">
                Mobile number <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                className="auth-welcome"
                placeholder="Enter Mobile number"
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                maxLength={13}
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
                onKeyUp={(e) =>
                  formik.setFieldValue(
                    "mobileNumber",
                    e.target.value.replace(/(?!^\+)[^\d]/g, "")
                  )
                }
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="error">{formik.errors.mobileNumber}</div>
              ) : null}
            </div>
            <div className="field md:col-12 mb-0">
              <label className="">
                Password <span className="asteric">&nbsp;*</span>
              </label>
              <Password
                className="auth-welcome"
                placeholder="Enter password"
                type="text"
                id="password"
                name="password"
                maxLength={30}
                value={formik.values.password}
                toggleMask
                onChange={formik.handleChange}
                feedback={false}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>

            <div className="field md:col-12 mb-0">
              <Button
                loading={loading}
                type="submit"
                className="custom-btn mt-3"
                label="Log In"
                disabled={loading}
              />
            </div>
            <p className="mx-auto" style={{ textAlign: "center" }}>
              If you dont have an account?{" "}
              <a onClick={handleRedirect}>Signup here</a>
            </p>
          </form>
        </div>
        <div className="field md:col-4"></div>
      </div>
    </div>
  );
};

export default LoginScreen;
