import React, { useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddEditUser from '../components/add_edit_user';


const Users = () => {
    const [dialog, setDialog] = useState();
    const data = [
        { name: "Ali", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 1', catagory: 'Teacher' },
        { name: "RAza", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 2', catagory: 'Student' },
        { name: "Abdullah", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 3', catagory: 'Staff' },
        { name: "RAza", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 2', catagory: 'Student' },
        { name: "Abdullah", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 3', catagory: 'Staff' },
        { name: "RAza", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 2', catagory: 'Student' },
        { name: "Abdullah", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 3', catagory: 'Staff' },
        { name: "RAza", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 2', catagory: 'Student' },
        { name: "Abdullah", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 3', catagory: 'Staff' },
        { name: "Abdullah", cnic: "30909-9898980-9", mobile: "030200012010", checkIn: '9:00Am', checkOut: '9:00Pm', gate: 'Gate 3', catagory: 'Staff' },
    ]

    const onHide = () => {
        setDialog(false)
    }
    const actionTemplate = (rowData) => (
        <div>
            <Button icon="pi pi-pencil" className="custom-btn-edit"  />
            <Button icon="pi pi-trash" className="custom-btn-delete"  />
        </div>
    );
    return (
        <>
            <Dialog visible={dialog} onHide={onHide}
                // header={editable ? "Edit Users" : "Add Users"}
                header="Add Users"
                style={{ width: "60vw" }}>
                <AddEditUser dialog={dialog} setDialog={setDialog} onHide={onHide} />
            </Dialog>
            <div className='card'>
                <div className='grid'>
                    <div className="md:col-10" ></div>
                    <div className="md:col-2" >
                        <div >
                            <Button className="custom-btn w100 " label="+ &nbsp;Add New" onClick={() => { setDialog(true) }} />
                        </div>
                    </div>
                    <div className='md:col-12' >
                        <DataTable
                            value={data}
                            scrollable
                            scrollHeight="450px"
                            paginator
                            rows={10}

                            globalFilterFields={["name", "cnic", "mobile"]}
                        >
                            <Column field='name' header="Mobile Number" />
                            <Column field='name' header="Name" />
                            <Column field='cnic' header="Gate Number" />
                            <Column field='mobile' header="Roles" />
                            <Column field='checkIn' header="Create Date" />
                            <Column field='checkOut' header="Modified Date" />
                            <Column field='' header="Action"  body={actionTemplate} style={{ minWidth: '12rem' }}  />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users